import configs from '@/configs'

export default {
    state() {
        return {
            theme: configs.theme,

            logos: {
                home: `${configs.theme.split('.')[0]}-home.png`,
                appBar: `${configs.theme.split('.')[0]}-app-bar.png`
            },

            bg: 1
        }        
    },

    mutations: {
        setBg(state, payload) {
            state.bg = payload
        },
    },
        
    getters: {
        getTheme: state => state.theme,
        getLogos: state => state.logos,
        getBg: state => state.bg,
    },
}
