<template>
    <div>
        <v-dialog v-model="getInvalidHashDialog" 
            max-width="500px"
            persistent
        >
            <v-card style="position: relative;">
                <v-card-title class="light--text"
                    :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
                    style="position: sticky; top: 0; width: 100%; z-index: 1000;"
                >
                    <span class="headline">Sessão expirada</span>
                </v-card-title>
                <v-card-text class="py-4">
                    <v-text-field color="primary"
                        label="Email"
                        type="text"
                        v-model="email"
                    ></v-text-field>

                    <v-text-field color="primary"
                        label="Senha"
                        prepend-icon="mdi-lock"
                        :type="showPassword ? 'text' : 'password'" 
                        :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                        @click:append="showPassword = !showPassword"
                        v-model="password"
                    ></v-text-field>
                </v-card-text>

                <v-card-actions class="px-6 py-6">
                    <v-spacer></v-spacer>

                    <v-btn @click="endSession()"
                        color="primary"
                        class="mr-3"
                        text
                    >
                        Finalizar
                    </v-btn>

                    <v-btn
                        color="primary"
                        @click="login()"
                        :loading="loading"
                        :disabled="password.length < 6"
                    >
                        Renovar sessão
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import configs from '@/configs'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    data() {
        return {
            email: null,
            showPassword: false,
            password: '',
            loading: false,
        }
    },

    computed: {
		...mapGetters('auth', ['getUser', 'getInvalidHashDialog', 'getUrlPathName'])
    },

    mounted() {
        window.addEventListener('keydown', this.keyDownHandler)
    },

    destroyed() {
        window.removeEventListener('keydown', this.keyDownHandler)
    },

    methods: {
		...mapMutations('auth', ['setInvalidHashDialog', 'setEnableSessionExpiredDialog', 'setUrlPathName']),
		...mapActions('auth', ['getHashAuth', 'logout', 'authenticate']),

        keyDownHandler(event) {
            if ((event.code === 'Enter' || event.code === 'NumpadEnter') && this.password.length >= 6) {
                this.login()
            }
        },

        async login() {
            this.loading = true

            try {
                const res = await this.authenticate({ email: this.email, password: this.password })
                if (res === 'success') {
                    this.$toast.success('Sessão renovada com sucesso')
                    this.setInvalidHashDialog(false)
                    this.setEnableSessionExpiredDialog(true)
                    this.loading = false
                    if (this.getUrlPathName) this.redirectToPathName(this.getUrlPathName)
                }
            } catch (err) {
                this.loading = false
                this.$fnError(err)
            }
        },

        redirectToPathName(pathName) {
            let url = null
            if (window.location.href.indexOf(':80') == -1)	{
                url = `https://${configs.system}.${configs.modulePath}.znaptech.com${pathName}`
            } else {
                url = `http://${configs.system}.${configs.modulePath}.znaptech:${configs.port}${pathName}`
            }

            this.setUrlPathName(null)
            return window.location.href = url
        },

        endSession() {
            return this.logout()
        }
    }
}
</script>

<style>

</style>
