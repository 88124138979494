const clients = [
    {
        id: 1,
        system: 'znap',
        module: 'ZNAP_PRODUCTION_PLANNING',
        modulePath: 'production',
        language: 'pt',
        port: 8085,
        theme: 'znap.js'
    },
    {
        id: 2,
        system: 'bauducco',
        module: 'ZNAP_PRODUCTION_PLANNING',
        modulePath: 'production',
        language: 'pt',
        port: 8285,
        theme: 'bauducco.js'
    },
    {
        id: 3,
        system: 'fan',
        module: 'ZNAP_PRODUCTION_PLANNING',
        modulePath: 'production',
        language: 'pt',
        port: 8185,
        theme: 'fan.js'
    }
]

const getClientConfigs = (clientId) => clients.find(client => client.id === clientId)
const configs = getClientConfigs(1)

export default {
    ...configs
}