export default [

    // USER PROFILE
    {
        name: 'UserProfile',
        path: '/user-profile',
        component: () =>
            import('@/views/UserProfile'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },

    // APPROVAL FLOW
    {
        name: 'ApprovalFlow',
        path: '/approval-flow',
        component: () =>
            import('@/views/ApprovalFlow'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'ApprovalFlowLevel',
        path: '/approval-flow-level',
        component: () =>
            import('@/views/ApprovalFlowLevel'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'ApprovalFlowTransaction',
        path: '/approval-flow-transaction',
        component: () =>
            import('@/views/ApprovalFlowTransaction'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    // {
    //     name: 'ApprovalFlowCostCenter',
    //     path: '/approval-flow-cost-center',
    //     component: () =>
    //         import('@/views/ApprovalFlowCostCenter'),
    //         meta: {
    //             requireAuth: true,
    //             redirectHomeIfLogged: false
    //         }
    // },
    {
        name: 'PlanningGroupVersion',
        path: '/planning-group-version',
        component: () =>
            import('@/views/PlanningGroupVersion'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'PlanningGroup',
        path: '/planning-group',
        component: () =>
            import('@/views/PlanningGroup'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'ApprovalFlowPlanningGroup',
        path: '/approval-flow-planning-group',
        component: () =>
            import('@/views/ApprovalFlowPlanningGroup'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'EventStatusPlanningGroup',
        path: '/event-status-planning-group',
        component: () =>
            import('@/views/EventStatusPlanningGroup'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },

    // ACCOUNT
    {
        name: 'ChartAccountGroup',
        path: '/chart-account-group',
        component: () =>
            import('@/views/ChartAccountGroup'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'ChartAccountGroupVersion',
        path: '/chart-account-group-version',
        component: () =>
            import('@/views/ChartAccountGroupVersion'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'AccountGroup',
        path: '/account-group',
        component: () =>
            import('@/views/AccountGroup'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'AccountGroupCalc',
        path: '/account-group-calc',
        component: () =>
            import('@/views/AccountGroupCalc'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'AccountGroupVersion',
        path: '/account-group-version',
        component: () =>
            import('@/views/AccountGroupVersion'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'AccountGroupReference',
        path: '/account-group-reference',
        component: () =>
            import('@/views/AccountGroupReference'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'AccountGroupSystemId',
        path: '/account-group-system-id',
        component: () =>
            import('@/views/AccountGroupSystemId'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },

    // EVENT
    {
        name: 'Event',
        path: '/event',
        component: () =>
            import('@/views/Event'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'EventConfig',
        path: '/event-config',
        component: () =>
            import('@/views/EventConfig'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },

    // PRODUCT
    {
        name: 'ProductProductionPlanning',
        path: '/product-production-planning',
        component: () =>
            import('@/views/ProductProductionPlanning'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
   
    // SALES PLANNING
    {
        name: 'ProductionPlanning',
        path: '/production-planning',
        component: () =>
            import('@/views/ProductionPlanning'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    // {
    //     name: 'CustomerGroup',
    //     path: '/customer-group',
    //     component: () =>
    //         import('@/views/CustomerGroup'),
    //         meta: {
    //             requireAuth: true,
    //             redirectHomeIfLogged: false
    //         }
    // },
    {
        name: 'DetaileProductionPlanning',
        path: '/detailed-production-planning',
        component: () =>
            import('@/views/DetailedProductionPlanning'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'ReportGallery',
        path: '/report-gallery',
        component: () =>
            import('@/views/ReportGallery'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'ProductionReport',
        path: '/production-report',
        component: () =>
            import('@/views/ProductionReport'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    }
]