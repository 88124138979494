import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'
import './plugins/axios'
import './plugins/vee'
import './plugins/vuetoastification'

import { filters, calendar, errorFunction } from '@/utils'
Vue.filter('dateFilter', filters.dateFilter)
Vue.filter('floatFilter', filters.floatFilter)
Vue.filter('cpfCnpjFilter', filters.cpfCnpjFilter)
Vue.prototype.$calendar = calendar
Vue.prototype.$fnError = errorFunction

Vue.config.productionTip = false

Vue.prototype.$ipUser = 'https://api.user.znaptech.com/'
Vue.prototype.$ipClient = 'https://api.client.znaptech.com/'
Vue.prototype.$ipSecurity = 'https://api.security.znaptech.com/'
Vue.prototype.$ipSales = 'https://api.sales.area.znaptech.com/'
Vue.prototype.$ipSalesPlanning = 'https://api.sales.planning.znaptech.com/'
Vue.prototype.$ipDynamicReport = 'https://api.dynamic.report.znaptech.com/'
Vue.prototype.$ipAccount = 'https://api.account.znaptech.com/'
Vue.prototype.$ipEvent = 'https://api.event.znaptech.com/'
Vue.prototype.$ipApprovalFlow = 'https://api.approval.flow.znaptech.com/'
Vue.prototype.$ipSalesArea = 'https://api.sales.area.znaptech.com/'
Vue.prototype.$ipOrganization = 'https://api.organization.znaptech.com/'
Vue.prototype.$ipUnit = 'https://api.unit.znaptech.com/'
Vue.prototype.$ipProduct = 'https://api.product.znaptech.com/'
Vue.prototype.$ipComment = 'https://api.comment.znaptech.com/'
Vue.prototype.$ipCustomer = 'https://api.customer.znaptech.com/'
Vue.prototype.$ipProductionPlanning = 'https://api.production.planning.znaptech.com/'
Vue.prototype.$ipPlPlanning = 'https://api.pl.planning.znaptech.com/'
Vue.prototype.$ipMessage = 'https://api.message.znaptech.com/'

Vue.prototype.$vuetify = vuetify

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
